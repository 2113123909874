import { cn } from '@/utils/tailwindUtils';
import React from 'react';

export default function Container({
  children,
  className,
  id,
}: {
  children: React.ReactNode;
  className?: string;
  id?: string;
}) {
  return (
    <div
      id={id}
      className={cn(
        'm-auto mx-auto h-full w-full max-w-[1440px] px-5',
        className,
      )}
    >
      {children}
    </div>
  );
}
