import React from 'react';
import AccessCard from './access-card';
import Container from '../container';
import { EditableField } from '@/components/EditableField';
import { useData } from '@/components/DataProvider';

const About = ({ about, hero }) => {
  const { sanityGenaiV2 } = useData();

  return (
    <div className="bg-[#E8E8E8]">
      <Container className="relative bg-[url(/dots-gray.png)] bg-cover bg-no-repeat px-5 pb-14 pt-10 md:pb-44 md:pt-40">
        <AccessCard
          hero={hero}
          className="absolute -top-11 right-0 hidden md:top-[72px] [@media(min-width:375px)]:block"
        />
        <div className="summit-speakers-heading">
          <h2 className="mb-10 text-[44px] font-bold leading-[48.4px] text-[#1A1A1A] md:mb-8 md:text-[88px] md:leading-[64px] [@media(min-width:375px)]:mb-40">
            <EditableField
              fieldPath={'about.title'}
              documentType={sanityGenaiV2._type}
              documentId={sanityGenaiV2._id}
              bColor="black"
            >
              {about.title}
            </EditableField>
          </h2>
          <p className="w-full max-w-[825px] text-left text-base font-normal leading-6 text-black md:text-lg md:leading-7">
            <EditableField
              fieldPath={'about.subTitle'}
              documentType={sanityGenaiV2._type}
              documentId={sanityGenaiV2._id}
              bColor="black"
            >
              {about.subTitle}
            </EditableField>
          </p>
        </div>
      </Container>
    </div>
  );
};

export default About;
