import React from 'react';
import Container from '../container';
import { PortableText } from '@portabletext/react';
import { EditableField } from '@/components/EditableField';
import { useData } from '@/components/DataProvider';

type Session = {
  description: string;
  subTitle?: string;
  from: string;
  to: string;
  title: string;
  videoId: string;
  videoTitle: string;
  _rawVideoDescription;
  speakers: {
    companyImage2: {
      asset: {
        url: string;
      };
    };
    companyName: string;
    fullName: string;
    id: string;
    link: string;
    role: string;
    image: {
      asset: {
        gatsbyImageData: any;
      };
    };
  }[];
};

const portableTextMap = {
  marks: {
    strong: ({ children }) => <span className="font-bold">{children}</span>,
    highlight: ({ children }) => (
      <span className="highlight-hero">{children}</span>
    ),
  },
};

const SessionsFinished = ({
  sessions,
}: {
  sessions: {
    title: string;
    oldTitle: string;
    sessions: Session[];
    oldSessions: Session[];
  };
}) => {
  const { sanityGenaiV2 } = useData();
  return (
    <div id="watch-on-demand" className="bg-[#1A1A1A]">
      <Container className="pb-20 pt-14 md:pb-0 md:pt-40">
        <div className="summit-speakers-heading">
          <h2 className="mb-7 font-dm-sans text-[44px] font-bold leading-[48.4px] text-white md:mb-9 md:text-[88px] md:leading-[64px]">
            <EditableField
              fieldPath={'sessions.title'}
              documentType={sanityGenaiV2._type}
              documentId={sanityGenaiV2._id}
              bColor="white"
            >
              {sessions.title}
            </EditableField>
          </h2>
          <EditableField
            fieldPath={'sessions.sessions'}
            documentType={sanityGenaiV2._type}
            documentId={sanityGenaiV2._id}
            bColor="white"
          >
            <ul className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:gap-10">
              {sessions.sessions
                ?.slice()
                .sort((a, b) => {
                  const fromDate = new Date(a.from);
                  const toDate = new Date(b.from);
                  return fromDate > toDate ? 1 : -1;
                })
                .map((i, index) => (
                  <li
                    key={index}
                    className={`flex flex-col rounded-lg bg-[#383838] p-6`}
                  >
                    <h3 className="mb-6 text-base font-bold text-[#A89AFF] md:min-h-[60px] lg:mb-4 lg:text-2xl">
                      {i.title}
                    </h3>{' '}
                    <img
                      style={{ width: '100%', display: 'block' }}
                      className="vidyard-player-embed !mt-0"
                      src={`https://play.vidyard.com/${i.videoId}.jpg`}
                      data-uuid={i.videoId}
                      data-v="4"
                      data-type="inline"
                    />
                    <div className="pt-8 text-[18px] text-base font-normal leading-[130%] tracking-[0.5px] text-white">
                      <PortableText
                        components={portableTextMap}
                        value={i._rawVideoDescription}
                      />
                    </div>
                    <ul className="mt-6">
                      {i.speakers.map((i) => {
                        return (
                          <li
                            key={i.id}
                            className="flex items-start gap-2 text-[18px] text-base font-normal leading-[130%] tracking-[0.5px] text-white"
                          >
                            • {i.fullName?.trim()}, {i.role?.trim()},{' '}
                            {i.companyName?.trim()}
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                ))}
            </ul>
          </EditableField>
        </div>
      </Container>
    </div>
  );
};

export default SessionsFinished;
