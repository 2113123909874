import React from 'react';
import CtaButton from '../CtaButton';
import AnimatedLogos from '../Logos';
import { extractDateAndTime, extractSessionTime } from '@/utils/date';
import { cn } from '@/utils/tailwindUtils';
import { EditableField } from '../EditableField';
import { useData } from '../DataProvider';

const GenAiHero = ({ hero }) => {
  const {
    title,
    version,
    eventDateTime,
    eventDateTimeEnd,
    ctaLink,
    timezone,
    subTitle,
    eventType,
    cost,
    logos,
  } = hero;

  const { formattedDate } = extractDateAndTime({
    eventDateTime,
    timezone,
  });

  const { formattedTime: startTime } = extractSessionTime({ eventDateTime });
  const { formattedTime: endTime } = extractSessionTime({
    eventDateTime: eventDateTimeEnd,
  });

  const data = [
    { value: eventType, path: 'hero.eventType' },
    { value: cost, path: 'hero.cost' },
    { value: 'October 29', path: 'hero.eventDateTime' },
    { value: `${startTime} - ${endTime} ${timezone}`, path: 'hero.timezone' },
  ];
  const { sanityGenaiV2 } = useData();

  return (
    <div className="relative overflow-hidden bg-[#070707] px-5">
      <div className="mx-auto max-w-[1440px] bg-[url(/dots.png)] bg-cover bg-no-repeat">
        <div className="pb-[72px] pt-[72px] md:py-24 lg:pb-28 lg:pt-16">
          <h1 className="relative font-dm-sans text-4xl text-[44px] font-bold leading-[46.2px] tracking-[-0.02em] text-white md:text-[72px] md:leading-[80px] lg:flex lg:items-end xl:text-[130px] xl:leading-[138px]">
            <EditableField
              fieldPath="hero.title"
              documentType={sanityGenaiV2._type}
              documentId={sanityGenaiV2._id}
            >
              <span className="mr-4 inline-block max-w-min md:mr-8">
                {title}
              </span>
            </EditableField>
            <EditableField
              fieldPath="hero.version"
              documentType={sanityGenaiV2._type}
              documentId={sanityGenaiV2._id}
            >
              <span className="relative text-[#4A2FF9]">{version}</span>
            </EditableField>
          </h1>
          <ul className="my-6 grid grid-cols-2 items-center justify-start gap-x-4 gap-y-2 border-y border-solid border-y-[#FFFFFF33] py-2.5 [@media(min-width:450px)]:flex [@media(min-width:450px)]:max-w-fit [@media(min-width:450px)]:gap-0">
            {data.map((item, index) => (
              <li
                className={cn(
                  `border-solid border-[#FFFFFF33] text-left font-overpass-mono text-sm font-medium leading-[28.5px] text-white md:text-xl md:font-medium md:leading-[28.5px]`,
                  {
                    'md:!pl-16 [@media(min-width:450px)]:border-l-[1px] [@media(min-width:450px)]:pl-4':
                      index != 0,
                    'md:!pr-16 [@media(min-width:450px)]:border-r-[1px] [@media(min-width:450px)]:pr-4':
                      index != data.length - 1,
                    'border-r-[1px] border-solid border-[#FFFFFF33]':
                      index === 0 || index === 2,
                  },
                )}
                key={item.value}
              >
                <EditableField
                  fieldPath={item.path}
                  documentType={sanityGenaiV2._type}
                  documentId={sanityGenaiV2._id}
                >
                  {item.value}
                </EditableField>
              </li>
            ))}
          </ul>
          <p className="mb-10 text-[22.5px] font-normal leading-[35px] text-[#FFFFFFCC]">
            <EditableField
              fieldPath={'hero.subTitle'}
              documentType={sanityGenaiV2._type}
              documentId={sanityGenaiV2._id}
            >
              {subTitle}
            </EditableField>
          </p>
          <EditableField
            fieldPath={'hero.ctaLink.label'}
            documentType={sanityGenaiV2._type}
            documentId={sanityGenaiV2._id}
          >
            <CtaButton
              className="w-full text-center sm:w-fit sm:text-left"
              type="regular"
              link={ctaLink}
            />
          </EditableField>
        </div>
      </div>
      <EditableField
        fieldPath={'hero.logos'}
        documentType={sanityGenaiV2._type}
        documentId={sanityGenaiV2._id}
      >
        <AnimatedLogos logos={logos} />
      </EditableField>
    </div>
  );
};

export default GenAiHero;
