import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from '@/components/common/layout';
import {
  Hero,
  About,
  Speakers,
  SessionsFinished,
} from '@/components/genai-productionize-v2';
import SEO from '@/components/seo';
import { DataProvider } from '@/components/DataProvider';

const GenAi = ({ data }) => {
  const { hero, about, sessions } = data.sanityGenaiV2;
  return (
    <Layout>
      <DataProvider data={data}>
        <Hero hero={hero} />
        <Speakers sessions={sessions} />
        <About about={about} hero={hero} />
        <SessionsFinished sessions={sessions} />
      </DataProvider>
    </Layout>
  );
};

export const query = graphql`
  query GenAiQuery {
    sanityGenaiV2 {
      _id
      _type
      seo {
        metaTitle
        _type
        __typename
        metaDescription
        image {
          _key
          asset {
            gatsbyImageData(width: 1280)
            url
            localFile(width: 1280) {
              publicURL
              childImageSharp {
                fixed(width: 1280) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
      }
      hero {
        title
        version
        ctaLink {
          label
          slug {
            current
          }
        }
        subTitle
        eventType
        cost
        eventDateTime
        eventDateTimeEnd
        timezone
        logos {
          asset {
            url
          }
        }
      }
      about {
        title
        subTitle
      }
      formSection {
        hubspotFormId
        hubspotPortalId
        subTitle
        title
        redirectLink {
          label
          slug {
            current
          }
        }
      }
      sessions {
        oldTitle
        title
        oldSessions {
          _key
          description
          subTitle
          title
          videoId
          from
          to
          speakers {
            _key
            order
            companyImage {
              asset {
                url
              }
            }
            companyName
            fullName
            id
            link
            role
            image {
              asset {
                gatsbyImageData
              }
            }
          }
        }
        sessions {
          _key
          description
          subTitle
          title
          videoId
          from
          to
          videoTitle
          _rawVideoDescription
          speakers {
            _key
            order
            companyImage {
              asset {
                url
              }
            }
            companyImage2 {
              asset {
                url
              }
            }
            companyName
            fullName
            id
            link
            role
            image {
              asset {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;

export default GenAi;

export const Head = () => {
  return (
    <>
      <SEO
        title={'GenAI Productionize 2.0'}
        description={'The premier conference for GenAI application development'}
        image={'/genai-productionize-2.0-og.png'}
        type="article"
        imageWidth={1200}
        imageHeight={670}
      />
      <script
        type="text/javascript"
        async
        src="https://play.vidyard.com/embed/v4.js"
      ></script>
    </>
  );
};
