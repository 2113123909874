import { parseISO, format } from 'date-fns';

export const extractDateAndTime = ({
  eventDateTime,
  timezone,
}: {
  eventDateTime: string;
  timezone: string;
}) => {
  const date = parseISO(eventDateTime);
  const formattedDate = format(date, 'MMMM d');
  const formattedTime = `${format(date, 'HH')} ${timezone}`;

  return {
    formattedDate,
    formattedTime,
  };
};

export const extractSessionTime = ({
  eventDateTime,
}: {
  eventDateTime: string;
}) => {
  const date = parseISO(eventDateTime);

  function removeLeadingZero(timeString) {
    if (timeString.startsWith('0')) {
      return timeString.slice(1);
    }
    return timeString;
  }

  const time = new Intl.DateTimeFormat('en-US', {
    timeZone: 'America/Los_Angeles',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  })
    .format(date)
    .replace(':00', '')
    .toUpperCase();

  const formattedTime = removeLeadingZero(time);

  return {
    formattedTime,
  };
};
