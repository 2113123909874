import React, { useEffect, useState } from 'react';

const HubspotGenAiForm = ({ formId, formSuccessMessageTitle }) => {
  const [submitSucess, setSubmitSucess] = useState(false);

  const createForm = () => {
    window.hbspt.forms.create({
      region: 'na1',
      portalId: '23114811',
      formId: formId,
      target: '#hubspotGenAiForm',
    });
  };

  const onHubSubmit = (event) => {
    if (
      event.data.type === 'hsFormCallback' &&
      event.data.eventName === 'onFormSubmitted'
    ) {
      setSubmitSucess(true);
    }
  };

  useEffect(() => {
    window.addEventListener('message', onHubSubmit);
    if (!window.hbspt) {
      const script = document.createElement('script');
      script.src = 'https://js.hsforms.net/forms/v2.js';
      document.body.appendChild(script);
      script.addEventListener('load', () => {
        if (window.hbspt) {
          createForm();
        }
      });
    } else {
      createForm();
    }
    return () => {
      window.removeEventListener('message', onHubSubmit);
    };
  }, []);

  return (
    <div className="mt-7">
      {submitSucess ? (
        <div>
          <p>{formSuccessMessageTitle}</p>
        </div>
      ) : (
        <div>
          <div id="hubspotGenAiForm" />
        </div>
      )}
    </div>
  );
};

export default HubspotGenAiForm;
